import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import orange from '../../../pictures/orange.png';
import loginImage from '../../../pictures/login.webp';
import { ReRenderContext } from '../../../context/ReRenderContext';
import axios from '../../../config/axios';
import { AuthContext } from '../../../context/AuthContext';
import { OpenCloseComponentContext } from '../../../context/OpenCloseComponentContext';
import { SearchContext } from '../../../context/SearchContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdDarkMode } from 'react-icons/md';
import { DarkThemeContext } from '../../../context/DarkThemeContext';
import { BsChevronCompactDown } from 'react-icons/bs';
import magnify from '../../../pictures/magnify.svg';
import { MdOutlineCancel } from 'react-icons/md';
import { RiLoginCircleLine } from 'react-icons/ri';
import { ProductContext } from '../../../context/ProductContext';
import { setRole } from '../../../services/localStorage';
import { useGoogleMapContext } from '../../../context/googleMap/googleMap';
import { useOrderContext } from '../../../context/UserOrderContext';

function Header({ setDarkThemeActive, darkThemeActive }) {
  const { displayDarkTheme, setDisplayDarkTheme } =
    useContext(DarkThemeContext);
  const navigate = useNavigate();
  const { logout, user, role, authState, csrfToken, setRole, setUser } =
    useContext(AuthContext);
  const {
    searchTerm,
    setSearchTerm,
    isFilteringFromContext,
    setIsFilteringFromContext,
  } = useContext(SearchContext);

  const {
    setGoogleMapAddress,
    setGoogleMapLatLng,
    setGoogleMapAddressName,
    setGoogleMapAddressSearchTerm,
  } = useGoogleMapContext();
  const { setCheckoutAddress, setLalamoveQuotation } = useOrderContext();

  const location = useLocation();
  const { reRender, setReRender } = useContext(ReRenderContext);
  const [products, setProducts] = useState([]);
  const [cartProductAmount, setCartProductAmount] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // if (isLoading) {
  //   return <button className='btn btn-square loading'></button>;
  // }
  const {
    searchByProductContext,
    setSearchByProductContext,
    searchTermProductContext,
    setSearchTermProductContext,
  } = useContext(ProductContext);
  const { showAccountOptions, setShowAccountOptions } = useContext(
    OpenCloseComponentContext
  );
  //-------------------------------------
  useEffect(() => {
    if (showAccountOptions === false) {
      setShowOptions(false);
    }
  }, [showAccountOptions]);
  //-------------------------------------
  const getCartProductAmountByUserId = async () => {
    // console.log(reRender);
    // console.log({ role: role });
    try {
      const resIsValidRefreshToken = await axios.get('/auth/checkRefreshToken');
      // console.log('resIsValidRefreshToken', resIsValidRefreshToken);
      if (resIsValidRefreshToken?.data?.isValid === false) {
        // console.log('refreshToken not found');
      }
      if (resIsValidRefreshToken?.data?.isValid === true) {
        const res = await axios.get(`/cart_products/amount/${+user?.id}`, {
          withCredentials: true,
        });
        setCartProductAmount(res?.data?.cartProductAmount);
      }
      // console.log(cartProductAmount);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (role === 'user' && user !== null) {
      getCartProductAmountByUserId();
    } else {
      setCartProductAmount(0);
    }
  }, [user, reRender, cartProductAmount, role]);

  useEffect(() => {
    if (searchTerm !== '') {
      const getAllProductBySearchTerm = async () => {
        try {
          setIsLoading(true);
          const res = await axios.get(
            `/products/getAllProductBySearchTerm/${searchTerm
              .trim()
              .replace(/\s/g, '')}`
          );
          if ((res.data.message = 'ไม่พบสินค้า')) {
            setProducts([]);
          }
          setProducts(res.data.products);
          // console.log(products);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      };
      setTimeout(() => {
        getAllProductBySearchTerm();
      }, '1000');
    }
  }, [searchTerm]);

  const handleClearCookie = async () => {
    try {
      const resDeleteAllCookie = await axios.get('/clear-all-cookie');
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdminClearCookie = async () => {
    try {
      const resAdminDeleteAllCookie = await axios.get(
        '/admin-clear-all-cookie'
      );
    } catch (error) {
      console.log(error);
    }
  };

  function replaceImageFileFormatWithWebP(url) {
    // Check if the string ends with a file extension (e.g., .png, .jpg)
    if (url && url.length > 3) {
      return url.slice(0, -3) + 'webp';
    }
    return url;
  }
  //-----------------------------------------------------------------------
  function generateSlug(name) {
    return name
      ?.toLowerCase()
      .replace(/ /g, '-') // Replace spaces with dashes
      .replace(/[^\w\-ก-๙]+/g, ''); // Remove special characters (supports Thai)
  }
  //-----------------------------------------------------------------------
  // console.log('isFilteringFromContext', isFilteringFromContext);

  return (
    <div
      className={`h-16 flex gap-x-[3px] sm:gap-4 justify-between px-2 ${
        user === null || role === 'user'
          ? 'bg-gradient-to-r from-yellow-400 to-orange-300'
          : 'bg-gradient-to-r from-orange-700 via-orange-700 to-orange-500'
      } sm:max-h-16 md:h-[150px]`}
      onClick={() => {
        setSearchTerm('');
        setSearchTermProductContext('');
        setSearchByProductContext('productName');
        setReRender((reRender) => !reRender); //สนใจให้มันเปลี่ยน
      }}
    >
      <div
        className='sm:text-sm md:text-xl sm:w-[155px] min-w-[35px] text-white w-fit flex gap-2 justify-start items-center cursor-pointer'
        onClick={() => {
          setIsFilteringFromContext(
            (isFilteringFromContext) => !isFilteringFromContext
          );
          setReRender((reRender) => !reRender);
          if (location.pathname === '/') {
            navigate(0); // Refresh the current page if already at root
          } else {
            navigate('/'); // Navigate to the root route
          }
        }}
      >
        <img
          src={orange}
          className='w-[33px] h-[33px] sm:w-[27px] sm:h-[27px] font-mono'
          alt='orange'
        />
        <h1
          className={`font-mono ${
            role === 'admin' || role === 'saleAdmin'
              ? 'text-white'
              : 'text-white'
          } hidden sm:block`}
        >
          TukSom.Co
        </h1>
      </div>
      <div
        className={`max-h-[740px] max-w-[661px] lg:w-[470px] xl:w-[455px] 2xl:w-[600px] md:mr-4 xl:ml-[235px] 2xl:ml-[230px] lg:mr-0 md:min-w-[358px] min-w-[227px] w-fit sm:px-0 sm:w-[450px] ${
          searchTerm ? 'z-50' : ''
        } grid grid-cols-1`}
      >
        <div className='flex justify-center items-center'>
          <input
            type='text'
            className={`${
              searchTerm ? 'z-50' : ''
            } appearance-none focus:outline-none border hover:border-2 focus:border-2 focus:border-orange-400 hover:border-orange-400 my-3 h-[42px] lg:w-[470px] pl-2 w-full xl:w-[455px] 2xl:w-[600px] sm:w-[450px] md:min-w-[358px] rounded-3xl text-gray-500`}
            placeholder={`🔍 ค้นหาสินค้า`}
            onChange={(event) => {
              setSearchTerm(event?.target?.value);
            }}
            value={searchTerm}
          />
        </div>
        <div className='flex justify-center max-h-[640px]'>
          <div
            className={` ${
              searchTerm.trim().replace(/\s/g, '')
                ? 'max-w-[227px] sm:min-w-[303.47px] sm:max-w-[450px] 2xl:max-w-[600px] lg:w-[470px] xl:w-[455px] 2xl:w-[600px] sm:w-[450px] md:min-w-[358px] border-[1px] w-full bg-white md:w-[450px] overflow-y-auto flex flex-col'
                : 'hidden'
            } `}
          >
            {isLoading ? (
              <>
                <div className='flex justify-center h-[655px] items-start'>
                  <span className='btn loading loading-ball loading-md bg-orange-400 border-0 m-2'>
                    <p className='text-lg text-white'>กำลังค้นหาสินค้า...</p>
                  </span>
                </div>
              </>
            ) : (
              <>
                {products?.length > 0 ? (
                  <>
                    {products?.map((el, idx) => {
                      return (
                        <Link
                          to={`/product/${generateSlug(el?.name)}/${el?.id}`}
                          key={idx}
                        >
                          <div
                            className='text-[12px] flex gap-3 sm:text-[14px] md:text-[16px] border-[1px] hover:border-orange-500 hover:bg-gradient-to-r hover:from-orange-100 hover:to-orange-50 border-orange-200 bg-white'
                            onClick={() => {
                              setSearchTerm('');
                            }}
                            key={el?.id}
                          >
                            <div className='h-[70px] w-[46.98px] lg:w-[56.98px] xl:w-[60px] bg-white overflow-hidden'>
                              <LazyLoadImage
                                className='object-contain h-[70px] w-[46.98px] lg:w-[56.98px] xl:w-[60px]'
                                alt={el?.name}
                                src={replaceImageFileFormatWithWebP(
                                  el?.main_image
                                )}
                                effect='blur'
                              />
                            </div>
                            <div className='flex items-center h-[70px] w-full'>
                              <h2 className='text-gray-500'>{el?.name}</h2>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className='  bg-white  text-center py-2'>
                      <p className='text-lg text-orange-500'>
                        ไม่พบสินค้าที่ท่านค้นหา
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='flex sm:justify-center lg:justify-end sm:gap-1 lg:gap-4 relative justify-center text-center w-[95px] sm:w-fit lg:w-fit xl:w-fit'>
        <div className='flex flex-col justify-center items-center w-[50px] sm:w-[140px] lg:w-[130px] xl:w-[223px]'>
          <div className='flex align-middle hover:scale-105'>
            <button
              className='relative flex items-center h-[63px] justify-center'
              id='menu-button'
              data-dropdown-toggle='dropdown'
              type='button'
              aria-expanded='true'
              aria-haspopup='true'
              onClick={() => {
                setShowOptions((showOptions) => !showOptions);
                setShowAccountOptions(
                  (showAccountOptions) => !showAccountOptions
                );
              }}
            >
              <LazyLoadImage
                src={loginImage}
                className={`border-2 bg-white rounded-lg max-w-[38px] hover:border-orange-500 ${
                  role === 'user' || role === 'admin'
                    ? 'border-yellow-300'
                    : 'border-white'
                }`}
                alt='logInImage'
                effect='blur'
              />
              {role ? (
                <div className='flex items-center'>
                  <p
                    className={`${
                      role === 'admin' || role === 'saleAdmin'
                        ? 'text-white'
                        : 'text-white'
                    } p-1 text-[13px] lg:text-[16px] text-left hidden md:flex xl:block`}
                  >
                    {user?.username || user?.name || user?.phoneNumber || '-'}
                  </p>
                  <div className='text-gray-500 hidden xl:block'>
                    {<BsChevronCompactDown size={20} />}
                  </div>
                </div>
              ) : (
                <div className='p-2 text-gray-400 hidden sm:flex'>
                  {<BsChevronCompactDown size={20} />}
                </div>
              )}
            </button>
          </div>
          {role === 'user' ? (
            <>
              <div
                className={`${
                  showOptions ? '' : 'hidden'
                }  relative h-[125px] rounded-md mr-[100px]`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex='-1'
                onClick={() => {
                  setShowOptions((showOptions) => !showOptions);
                  setShowAccountOptions(
                    (showAccountOptions) => !showAccountOptions
                  );
                }}
              >
                <div id='py-1' className='' role='none'>
                  <ul className='text-white rounded-lg bg-orange-600 z-50 absolute w-[90px] ml-2 sm:ml-0'>
                    {!role && (
                      <div className=''>
                        <li className='rounded-md hover:bg-orange-700 w-[110px] px-1'>
                          <Link
                            className=''
                            to='/login'
                            id='menu-item-1'
                            tabIndex='-1'
                            role='menuitem'
                          >
                            เข้าสู่ระบบ
                          </Link>
                        </li>
                        {/* <li className='hover:bg-orange-700 bg-orange-500 px-1 cursor-pointer hover:cursor-pointer'>
                          <Link
                            className='cursor-pointer'
                            to='/signup'
                            id='menu-item-2'
                            tabIndex='-1'
                            role='menuitem'
                          >
                            สมัครสมาชิก
                          </Link>
                        </li> */}
                      </div>
                    )}
                    <li className=' hover:bg-orange-700 bg-orange-600 cursor-pointer  px-1'>
                      <Link
                        className=''
                        to='/user_purchase_order_report'
                        id='menu-item-3'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        My Orders
                      </Link>
                    </li>
                    <li className=' hover:bg-orange-700 bg-orange-400 cursor-pointer'>
                      <Link
                        className=''
                        to='/profile'
                        id='menu-item-3'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        บัญชีของฉัน
                      </Link>
                    </li>
                    {role ? (
                      <>
                        <li className='rounded-b-md hover:bg-orange-700 bg-orange-300 cursor-pointer '>
                          <Link
                            className=''
                            to=''
                            id='menu-item-4'
                            tabIndex='-1'
                            role='menuitem'
                          >
                            <button
                              className=''
                              onClick={async () => {
                                setUser(null);
                                await logout();
                                await handleClearCookie();
                                // setRole(null);
                                setGoogleMapAddress('');
                                setGoogleMapLatLng(null);
                                setGoogleMapAddressName('');
                                setGoogleMapAddressSearchTerm('');
                                setCheckoutAddress(null);
                                setLalamoveQuotation(null);
                              }}
                            >
                              Sign Out
                            </button>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${
                showOptions ? '' : 'hidden'
              }  relative w-[115px] mr-8 sm:mr-0 sm:w-[120px] sm:pl-0 lg:w-[140px] h-[190px] rounded-md  
                    `}
              role='menu'
              aria-orientation='vertical'
              aria-labelledby='menu-button'
              tabIndex='-1'
              onClick={() => {
                setShowOptions((showOptions) => !showOptions);
                setShowAccountOptions(
                  (showAccountOptions) => !showAccountOptions
                );
              }}
            >
              <ul className='text-[12px] w-[95px] sm:text-[15px] mr-6 lg:mr-6 sm:mr-0 text-white rounded-lg bg-orange-400  z-50 absolute  sm:m-0 ml-6  sm:w-[120px] items-center'>
                {!role && (
                  <div className=''>
                    <li className='rounded-md hover:bg-orange-600 bg-orange-500 px-1 cursor-pointer hover:cursor-pointer'>
                      <Link
                        className='flex justify-center items-center gap-1'
                        to='/login'
                        id='menu-item-1'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        <RiLoginCircleLine color='white' />
                        <p className='font-bold hover:scale-105 cursor-pointer'>
                          เข้าสู่ระบบ
                        </p>
                      </Link>
                    </li>
                    {/* <li className='hover:bg-orange-600 bg-orange-400 rounded-b-lg cursor-pointer px-1 hover:cursor-pointer'>
                      <Link
                        className=''
                        to='/signup'
                        id='menu-item-1'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        <p className='font-bold hover:scale-105 cursor-pointer'>
                          สมัครสมาชิก
                        </p>
                      </Link>
                    </li> */}
                  </div>
                )}
                {role === 'admin' || role === 'saleAdmin' ? (
                  <>
                    <li className=' hover:bg-orange-700 bg-orange-500 cursor-pointer'>
                      <Link
                        className=''
                        to='/order_admin'
                        id='menu-item-3'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        รายการสั่งซื้อลูกค้า
                      </Link>
                    </li>
                    {role === 'admin' ? (
                      <li className=' hover:bg-orange-700 bg-orange-400 cursor-pointer'>
                        <Link
                          className=''
                          to='/dynamicAddProductAdmin'
                          id='menu-item-3'
                          tabIndex='-1'
                          role='menuitem'
                        >
                          สั่งซื้อสินค้า
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    <li className=' hover:bg-orange-700 bg-orange-300 cursor-pointer'>
                      <Link
                        className=''
                        to='/addProductAdmin'
                        id='menu-item-3'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        เพิ่มสินค้า
                      </Link>
                    </li>
                    <li className='rounded-b-md hover:bg-orange-700 bg-orange-200 cur '>
                      <Link
                        className=''
                        to=''
                        id='menu-item-4'
                        tabIndex='-1'
                        role='menuitem'
                      >
                        <button
                          className=''
                          onClick={async () => {
                            setUser(null);
                            await logout();
                            await handleAdminClearCookie();
                            // setRole(null);
                          }}
                        >
                          Sign Out
                        </button>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          )}
        </div>
        {role === 'user' || role === null || role === '' ? (
          <div className='flex justify-center items-center lg:px-2'>
            <div
              onClick={() => {
                if (role === 'user') {
                  navigate('/cart_products');
                }
                if (role === '' || role === null) {
                  navigate('/login');
                }
              }}
              className='hover:scale-105 relative flex align-middle justify-center nav-link bg-gradient-to-r from-orange-600 to-orange-500 text-gray-50 p-2
                     hover:bg-orange-700 rounded-full h-[38px] w-fit lg:w-[135px] cursor-pointer'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth='2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'
                />
              </svg>
              <span className='absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white text-sm leading-4 text-center'>
                {cartProductAmount}
              </span>
              <p className='hidden lg:block'>ตระกร้าสินค้า</p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Header;
