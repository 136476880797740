import React from 'react';
import orange from '../../../pictures/orange.png';
import { useNavigate } from 'react-router-dom';
import { BsTelephone } from 'react-icons/bs';
function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <footer className='bg-gradient-to-r from-gray-100 h-[60px] sm:h-auto to-white border text-neutral-content flex justify-center py-3 px-4 items-center gap-7 sm:gap-2'>
        <div
          className='text-xl text-gray-500 flex justify-center items-center cursor-pointer w-full sm:w-fit'
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            src={orange}
            className='w-[30px] md:w-[33px] h-[30px] md:h-[33px] font-mono'
            alt='orange'
          />
          <h1 className='font-mono text-orange-500'>TukSom.Co</h1>
        </div>
        <p className='text-gray-700 text-[12.5px] sm:text-sm flex items-center py-2 sm:py-0 leading-tight h-[60px] sm:h-auto'>
          Providing reliable building materials since 1957
        </p>
      </footer>
      {/* <div className='flex p-4 bg-gradient-to-b from-green-500 to-green-700  text-white  justify-center items-center'> */}
      <div className='bg-gradient-to-r from-gray-100 to-white text-orange-500 flex justify-center gap-1 items-center text-[18px] py-2'>
        <BsTelephone />
        <p>02-5858491 02-9131020</p>
      </div>
      <div className='text-gray-700 flex p-4 bg-gradient-to-r from-yellow-400 to-orange-300 justify-center items-center text-[12.5px] sm:text-sm'>
        <p>Copyright © 1957 - All right reserved by Tuksom Co.,Ltd</p>
      </div>
    </>
  );
}

export default Footer;
